import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import './Header.css'; // Import the CSS file

const Header = () => {
  return (
    <div>
      <AppBar position="static" className="appBar">
        <Toolbar>
          <Typography variant="h5" style={{ flexGrow: 1}}>
            Manufacturing Platform
          </Typography>
          <Box>
            <Button>Materials</Button>
            <Button>Technologies</Button>
            <Button>Pricing</Button>
            <Button>Contact</Button>
            <Button>Login</Button>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
