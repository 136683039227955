import { useMemo } from 'react';
import ModelViewer from './ModelViewer';

const Home = ({ modelData, edgeData, onModelDataChange }) => {

  const modelViewer = useMemo(() => {
    return modelData ? (
      <ModelViewer
        modelData={modelData}
        edgeData={edgeData}
        onModelDataChange={onModelDataChange}
      />
    ) : null;
  }, [modelData, edgeData, onModelDataChange]);

  // Added missing dependency ^

  return (
    <div style={{width:'100%', height:'100%'}}>
      {modelViewer}
    </div>
  )
}

export default Home;