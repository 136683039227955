import React, { useState, useCallback, useMemo } from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import OpacityIcon from '@mui/icons-material/Opacity';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { StyledIconButton, StyledTreeItem, createStyledIcon } from './ThreeViewComponents';
import FileUpload from './FileUpload';
import axios from 'axios';

export default function CustomizedTreeView({ onModelDataChange }) {
  // Define dialog content components
  const GeometryDialogContent = () => (
    <FileUpload onModelDataChange={handleModelDataChange} handleClose={handleClose} />
  );

  const GenDialogContent = () => (
    <p>Content specific to ... goes here.</p>
  );

  // Initial tree data
  const initialTreeData = [
    {
      label: 'Geometries',
      type: 'Folder',
      dialogComponent: GeometryDialogContent,
      nodeId: `node-${Math.random().toString(36).substr(2, 9)}`,
      children: [],
    },
    {
      label: 'Filament Winding',
      type: 'AllInclusive',
      dialogComponent: GenDialogContent,
      nodeId: `node-${Math.random().toString(36).substr(2, 9)}`,
      children: [
        {
          label: 'Materials',
          type: 'Folder',
          nodeId: `node-${Math.random().toString(36).substr(2, 9)}`,
          children: [
            {
              label: 'Fibers',
              type: 'Opacity',
              dialogComponent: GenDialogContent,
              nodeId: `node-${Math.random().toString(36).substr(2, 9)}`,
            },
            {
              label: 'Matrix',
              type: 'BubbleChart',
              dialogComponent: GenDialogContent,
              nodeId: `node-${Math.random().toString(36).substr(2, 9)}`,
            },
          ],
        },
      ],
    },
  ];

  const [localTreeData, setLocalTreeData] = useState(initialTreeData);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState({
    nodeId: null,
    dialogContent: '',
    dialogComponent: null,
  });

  const [filledInStatus, setFilledInStatus] = useState(() => {
    const status = {};
    const setNodeStatus = (node) => {
      if (node.nodeId) {
        status[node.nodeId] = false;
        node.children?.forEach(setNodeStatus);
      }
    };

    initialTreeData.forEach(setNodeStatus);
    return status;
  });

  // Add a file to the tree
  const addFileToTree = useCallback((filename, newModelData, filePath) => {
    const updatedTreeData = [...localTreeData];

    const geometriesNode = updatedTreeData.find((node) => node.label === 'Geometries');

    if (geometriesNode) {
      const newChildNodeId = `node-${Math.random().toString(36).substr(2, 9)}`;
      const newChild = {
        label: filename,
        type: 'ViewInAr',
        modelData: newModelData,
        filePath: filePath, // Store the file path
        nodeId: newChildNodeId,
      };

      geometriesNode.children = [...(geometriesNode.children || []), newChild];

      setFilledInStatus((prevStatus) => ({
        ...prevStatus,
        [geometriesNode.nodeId]: true, // Set the status of 'Geometries' to true
        [newChildNodeId]: true, // Set the status of the new child to true
      }));
    }

    setLocalTreeData(updatedTreeData);
  }, [localTreeData, setFilledInStatus]);


  const handleFileClick = useCallback(async (filename) => {
    console.log("Processing file:", filename); // Debugging log

    try {
      const response = await axios.post('http://10.30.2.165:8080/process_model', { filename });

      if (response.data.success) {
        console.log("Processed model data:", response.data.modelData);
        // Handle the processed data as needed
      } else {
        console.error('Failed to process the model:', response.data.message);
      }
    } catch (error) {
      console.error('Error processing file:', error);
    }
  }, []);



  // Handle model data change
  const handleModelDataChange = useCallback((newModelData, newEdgeData, filename) => {
    onModelDataChange(newModelData, newEdgeData);
    addFileToTree(filename, newModelData);
  }, [onModelDataChange, addFileToTree]);

  // Handle add button click
  const handleAddClick = useCallback((nodeId, content, dialogComponent) => {
    setSelectedNode({ nodeId, dialogContent: content, dialogComponent });
    setDialogOpen(true);
  }, []);

  // Handle dialog close
  const handleClose = useCallback(() => {
    setDialogOpen(false);
    setSelectedNode({ nodeId: null, dialogContent: '', dialogComponent: null });
  }, []);

  // Define icons using useMemo
  const icons = useMemo(
    () => ({
      Folder: createStyledIcon(FolderIcon),
      Settings: createStyledIcon(SettingsIcon),
      BubbleChart: createStyledIcon(BubbleChartIcon),
      Opacity: createStyledIcon(OpacityIcon),
      AllInclusive: createStyledIcon(AllInclusiveIcon),
      ViewInAr: createStyledIcon(ViewInArIcon),
      // ... other icons
    }),
    []
  );

  // Create a tree item
  const createTreeItem = useCallback((item) => {
      const testClickHandler = () => {
        console.log("Item clicked:", item.label);
        handleFileClick(item.label); // Directly call with a test value
      };
      const Icon = icons[item.type] || FolderIcon;
      const { nodeId } = item;
      console.log(`Status for ${nodeId}:`, filledInStatus[nodeId]);

      const renderTreeItemLabel = (label, nodeId, dialogComponent) => (
        <Box display="flex" alignItems="center">
          <Box position="relative">
            <Icon />
            <CheckCircleIcon
              style={{
                color: filledInStatus[nodeId] ? 'green' : 'grey',
                position: 'absolute',
                top: 0,
                right: 0,
                fontSize: 'small',
                transform: 'translate(10%, 100%)',
                zIndex: 1000, // Ensuring it's on top of other elements
              }}
            />
          </Box>
          <Box flexGrow={1}>{label}</Box>
          {dialogComponent && (
            <StyledIconButton
              size="small"
              onClick={() => handleAddClick(nodeId, label, dialogComponent)}
            >
              <AddCircleOutlineIcon />
            </StyledIconButton>
          )}
        </Box>
      );

    const itemClickHandler = item.filePath ? (event) => {
      event.stopPropagation();
      handleFileClick(item.filePath);
    } : null;

    return (
      <StyledTreeItem
        key={item.nodeId}
        nodeId={nodeId}
        label={renderTreeItemLabel(item.label, nodeId, item.dialogComponent)}
        icon={''}
        onClick={testClickHandler} // Add onClick event
      >
        {item.children?.map((child) => createTreeItem(child))}
      </StyledTreeItem>
    );
  }, [icons, handleAddClick, filledInStatus, handleFileClick]); // Add handleFileClick to dependencies

  return (
    <Box display="flex" sx={{ height: '100%', overflowY: 'auto' }}>
      <TreeView
        aria-label="customized"
        defaultCollapseIcon={<RemoveIcon />}
        defaultExpandIcon={<AddIcon />}
        sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
      >
        {localTreeData.map(createTreeItem)}
      </TreeView>
      {selectedNode.dialogComponent && (
        <Dialog open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add {selectedNode.dialogContent}</DialogTitle>
          <DialogContent>
            <selectedNode.dialogComponent />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose}>Add</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}