import React from 'react';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import IconButton from '@mui/material/IconButton';
import { useSpring, animated } from '@react-spring/web';
import Collapse from '@mui/material/Collapse';
import { styled, alpha } from '@mui/material/styles';

// TransitionComponent for animations
export function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(0,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 0}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

// Styled IconButton with theme spacing
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1),
}));

// Function to create styled icons with primary color background
export const createStyledIcon = (IconComponent) => styled(IconComponent)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  padding: '0.15em',
  color: theme.palette.text.primary,
}));


// CustomTreeItem using forwardRef for the TreeItem component
export const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  return <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />;
});

// StyledTreeItem with custom styles
export const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({

  // Apply custom icon styling
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .MuiSvgIcon-root': {
      ...createStyledIcon({ theme }).style, // Apply the styled icon function
    },
  },

  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.5,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
    [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.primary,
    height: '50px', // Example height, adjust as needed
    fontWeight: theme.typography.fontWeightRegular,

    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightMedium,
    },

  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));
