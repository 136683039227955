import { useRef, useMemo } from "react";
import * as THREE from "three";

const ModelRender = ({ modelData, edgeData }) => {
  const mesh = useRef();

  // Extract model JSON string from modelData
  const key = Object.keys(modelData)[0];
  const modelArray = modelData[key];
  const jsonStr = modelArray[7];

  // Parse modelData and create geometry for the Model component
  const geometry = useMemo(() => {
    const parsedModelData = JSON.parse(jsonStr);
    const vertexArray = new Float32Array(parsedModelData.data.attributes.position.array);
    const bufferGeometry = new THREE.BufferGeometry();
    bufferGeometry.setAttribute("position", new THREE.BufferAttribute(vertexArray, 3));
    bufferGeometry.computeVertexNormals();
    return bufferGeometry;
  }, [jsonStr]);

  // Render edges for the Edges component
  const renderedEdges = Object.keys(edgeData).map((key) => {
    const edgeInfo = edgeData[key];
    const jsonStr = edgeInfo[2]; // Edge data as a JSON string
    const parsedEdgeData = JSON.parse(jsonStr);
    const vertexArray = new Float32Array(parsedEdgeData.data.attributes.position.array);
    const bufferGeometry = new THREE.BufferGeometry();
    bufferGeometry.setAttribute("position", new THREE.BufferAttribute(vertexArray, 3));
    return (
      <line key={key}>
        <bufferGeometry attach="geometry" {...bufferGeometry} />
        <lineBasicMaterial attach="material" color="#202020" linewidth={2} />
      </line>
    );
  });

  return (
    <>
      {/* Model component */}
      <mesh ref={mesh} geometry={geometry} castShadow receiveShadow>
        <meshStandardMaterial color="#808080" />
      </mesh>
      {/* Edges component */}
      {renderedEdges}
    </>
  );
};

export default ModelRender;