import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    background: {
      default: '#e8e2db', // Lightest color for the background
    },
    primary: {
      main: '#fab95b', // Light color for primary elements
    },
    secondary: {
      main: '#f16821', // Medium color for secondary elements
    },
    text: {
      primary: '#1a3263', // Dark color for text
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#fab95b',
          color: '#1a3263',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff9f2',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fab95b', // Custom background color for AppBar
          color: '#1a3263', // Custom text color for AppBar
        },
      },
    },
  },
  // ... other theme settings
});

export default Theme;
