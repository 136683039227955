import { memo, Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Bounds,
  useBounds,
  Center,
  GizmoHelper,
  GizmoViewcube,
  AccumulativeShadows,
  RandomizedLight,
  OrbitControls,
  Environment,
} from "@react-three/drei";
import ModelRender from './ModelRender';


// SelectToZoom component wraps children in a group with a click handler
// Clicking any object will refresh and fit bounds
function SelectToZoom({ children }) {
  const api = useBounds();
  return (
    <group>
      <mesh
        onClick={(e) => {
          e.stopPropagation();
          if (e.delta <= 2) {
            api.refresh(e.object).fit();
          }
        }}
      >
        {children}
      </mesh>
    </group>
  );
}

// Shadows component for accumulative shadows and randomized light
const Shadows = memo(() => (
  <AccumulativeShadows temporal frames={100} color="#9d4b4b" colorBlend={0.5} alphaTest={0.9} scale={20}>
    <RandomizedLight amount={15} radius={100} position={[100, 100, 100]} />
  </AccumulativeShadows>
));

// ModelViewer component that sets up the canvas
// and combines all other components, including model, edges, controls, and environment
const ModelViewer = ({ modelData, edgeData }) => {
  return (
    <Canvas
      shadows
      orthographic
      colorManagement
      camera={{
        zoom: 1,
        position: [100, 100, 100],
        near: 0.1,
        far: 1000,
      }}
      style={{ width: '100%', height: '100%' }}
    >
      <Suspense fallback={null}>
        <Bounds fit clip observe margin={1.1}>
          <SelectToZoom>
            <group position={[0, 0, 0]}>
              <Center>
                <mesh castShadow>
                  <ModelRender modelData={modelData} edgeData={edgeData}/>
                </mesh>
              </Center>
              <Shadows />
            </group>
          </SelectToZoom>
        </Bounds>
      </Suspense>
      <GizmoHelper alignment="bottom-right" margin={[80, 80]} onTarget={(orbitControls) => orbitControls.target}>
        <GizmoViewcube />
      </GizmoHelper>
      <OrbitControls makeDefault />
      <Environment preset="studio" />
    </Canvas>
  );
};

export default ModelViewer;