import React, { useState, useCallback } from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import Theme from './components/Theme';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Home from './components/Home';
import ThreeView from './components/ThreeView';
import Paper from '@mui/material/Paper';

const App = () => {
  const [modelData, setModelData] = useState(null);
  const [edgeData, setEdgeData] = useState(null);
  const [sidebarWidth, setSidebarWidth] = useState(250);
  const [isDragging, setIsDragging] = useState(false);

  const handleModelDataChange = useCallback((newModelData, newEdgeData) => {
    setModelData(newModelData);
    setEdgeData(newEdgeData);
  }, []);

  const startDrag = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const stopDrag = useCallback(() => {
    setIsDragging(false);
  }, []);

  const doDrag = useCallback((e) => {
    if (isDragging) {
      const newWidth = Math.min(400, Math.max(250, e.clientX));
      setSidebarWidth(newWidth);
    }
  }, [isDragging]);

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <div className="App" onMouseMove={doDrag} onMouseUp={stopDrag} onMouseLeave={stopDrag}>
        <Header />
        <div className="content-row">
          <div className="main-content">
            <Home modelData={modelData} edgeData={edgeData} onModelDataChange={handleModelDataChange} />
          </div>
        </div>
        <div className="three-view-overlay">
          <Paper square variant="outlined" style={{ width: sidebarWidth }} className="sidebar">
            <ThreeView onModelDataChange={handleModelDataChange} />
            <div className="resize-handle" onMouseDown={startDrag}></div>
          </Paper>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
