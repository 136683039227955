// FileUpload.js
import './FileUpload.css';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@mui/material';

import axios from 'axios';

const uploadModel = async (file, onModelDataChange) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post('http://10.30.2.165:8080/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.data.success) {
      console.log("Received modelData:", response.data.modelData);
      console.log("Received edgeData:", response.data.edgeData); // Add this line to log edgeData
      onModelDataChange(response.data.modelData, response.data.edgeData, file.name); // Pass filename
    } else {
      console.error('File upload failed');
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

const FileUpload = ({ onModelDataChange }) => {
  const [uploading, setUploading] = useState(false);
  const onDrop = async (acceptedFiles) => {
    setUploading(true);
    const file = acceptedFiles[0];
    await uploadModel(file, onModelDataChange);
    setUploading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Button
      {...getRootProps()}
      component="div"
      variant="contained"
      color="primary"
      size="large"
      disabled={uploading}
    >
      <input {...getInputProps()} />
      {uploading ? 'Analyzing...' : 'Upload Your Model'}
    </Button>
  );
}

export default FileUpload;